import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import { PostData } from '../../auth/PostData';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
  const [userPlan, setUserPlan] = useState([]);
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}
  useEffect(() => {
    PostData('getuserinfo').then( response => {     
			if( response !== 'invalid'){
				if(response.status === "success"){
						setUserPlan(response.user_plan);
				}
			}
		})
  },[]);

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu, index) => {      
        return (
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              key={menu.key}
              title={setLocale(localization, menu.title)}
              className={'menu-'+index}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.fonticon ? 
                        (<i className={subMenuFirst.fonticon}></i>) 
                      :
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >                    
                    {subMenuFirst.submenu.map((subMenuSecond) => {
                      let disabled    = false;
                      let spanContent = '';
                      if( typeof subMenuSecond.plan  !== "undefined" ){
                        if( subMenuSecond.plan == 'Pro' ){
                          if( userPlan.indexOf('LG002') === -1 && userPlan.indexOf('LG003') === -1 ){
                            disabled = true;
                            spanContent = (<span className="planFlag proFlag">Pro</span>)
                          }
                        }else if( subMenuSecond.plan == 'Citation'){
                          if( userPlan.indexOf('LG004') === -1 ){
                            disabled = true;
                            spanContent = (<span className="planFlag citationFlag">Citation</span>)
                          }
                        }else if( subMenuSecond.plan == 'Agency'){
                          if( userPlan.indexOf('LG005') === -1 && userPlan.indexOf('LG006') === -1 ){
                            disabled = true;
                            spanContent = (<span className="planFlag agencyFlag">Agency</span>)
                          }
                        }
                      }
                      return (
                              <Menu.Item 
                                key={subMenuSecond.key}
                                disabled={disabled}
                              >
                                {subMenuSecond.fonticon ? 
                                  (<i className={subMenuSecond.fonticon}></i>) 
                                :
                                  subMenuSecond.icon ? (
                                    <Icon type={subMenuSecond?.icon} />
                                  ) : null
                                }                                
                                <span>
                                  {setLocale(localization, subMenuSecond.title)}{spanContent}
                                </span>
                                <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                              </Menu.Item>
                            )
                    })}
                  </SubMenu>
                ) : (
                  <Menu.Item 
                    key={subMenuFirst.key}
                    disabled={
                      typeof subMenuFirst.plan === "undefined" ? false : ( ( subMenuFirst.plan === "Pro" && userPlan.indexOf('LG002') === -1 && userPlan.indexOf('LG003') === -1) ? true : (( subMenuFirst.plan === "Citation" && userPlan.indexOf('LG004') === -1) ? true : (( subMenuFirst.plan === "Agency" &&  userPlan.indexOf('LG005') === -1 && userPlan.indexOf('LG006') === -1) ? true : false)))
                    }
                  > 
                    {
                      subMenuFirst.fonticon ? 
                       (<i className={subMenuFirst.fonticon}></i>) 
                      :
                      subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null
                    }
                    <span>{setLocale(localization, subMenuFirst.title)}{typeof subMenuFirst.plan === "undefined" ? "" : ( ( subMenuFirst.plan === "Pro" && userPlan.indexOf('LG002') === -1 && userPlan.indexOf('LG003') === -1) ? <span className="planFlag citationFlag">Pro</span> : (( subMenuFirst.plan === "Citation" && userPlan.indexOf('LG004') === -1) ? <span className="planFlag citationFlag">Citation</span> : (( subMenuFirst.plan === "Agency" && userPlan.indexOf('LG005') === -1 && userPlan.indexOf('LG006') === -1) ? <span className="planFlag citationFlag">Agency</span> : "")))}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item>
          )
        )
      })}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                
                <Menu.Item
                  key={subMenuFirst.key}                  
                >
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
