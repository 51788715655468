//import fetch from 'auth/FetchInterceptor'
import {PostBeforeLogin, PostData} from '../auth/PostData';

const JwtAuthService = {}

JwtAuthService.login = function (data) {
	return PostBeforeLogin('/login', data);
}

JwtAuthService.signUp = function (data) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

export default JwtAuthService