import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import "../../assets/css/custom.css";
import "../../assets/scss/index.scss";
import "../../assets/css/ui-font/ui-font-line.css"
import "../../assets/css/swal.css"

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboards`))} />
				{/* <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} /> */}
				<Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
				<Route path={`${APP_PREFIX_PATH}/facebook-search`} component={lazy(() => import(`./facebook`))} />
				<Route path={`${APP_PREFIX_PATH}/google-search`} component={lazy(() => import(`./google`))} />
				<Route path={`${APP_PREFIX_PATH}/campaigns`} component={lazy(() => import(`./manageleads`))} />
				{/* <Route path={`${APP_PREFIX_PATH}/leads`} component={lazy(() => import(`./leads`))} /> */}
				<Route path={`${APP_PREFIX_PATH}/videos`} component={lazy(() => import(`./videos`))} />
				<Route path={`${APP_PREFIX_PATH}/resources`} component={lazy(() => import(`./resources`))} />
				<Route path={`${APP_PREFIX_PATH}/upgrades`} component={lazy(() => import(`./upgrades`))} />
				<Route path={`${APP_PREFIX_PATH}/contact`} component={lazy(() => import(`./contact`))} />
				<Route path={`${APP_PREFIX_PATH}/leads`} component={lazy(() => import(`./leads`))} />
				<Route path={`${APP_PREFIX_PATH}/clients`} component={lazy(() => import(`./clients`))} />
				<Route path={`${APP_PREFIX_PATH}/report`} component={lazy(() => import(`./report`))} />
				<Route path={`${APP_PREFIX_PATH}/checklist`} component={lazy(() => import(`./checklist`))} />
				<Route path={`${APP_PREFIX_PATH}/localagency`} component={lazy(() => import(`./localagency`))} />
				<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
			</Switch>
		</Suspense>
	)
}

export default React.memo(AppViews);
