import { API_BASE_URL } from 'configs/AppConfig'
import StatusValidation from './Validation';
import { AUTH_TOKEN } from 'redux/constants/Auth'
//import Cookies from 'js-cookie'

export function PostBeforeLogin(type, userData) {
    return new Promise((resolve, reject) => {
        fetch( API_BASE_URL + type, {
            method:'POST',
            body:JSON.stringify(userData),
            headers: {'Content-Type':'application/json'},
        })
        .then((response) => StatusValidation(response))
        .then((responseJson) => {
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function PostData(type, userData, formData = false) {
    return new Promise((resolve, reject) => {
        fetch( API_BASE_URL + '/' + type, {
            method:'POST',
            body: formData ? userData :JSON.stringify(userData),
            headers: {
                'Content-Type': formData ? 'multipart/form-data' : 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
            },
        })
        .then((response) => StatusValidation(response) )
        .then((responseJson) => {            
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error);
        });
    });
}